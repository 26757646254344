import React, { useState } from "react";
import "./cards.component.css";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useParams } from "react-router-dom";

import axios from "axios";
import { switchUrls } from "../../../api/index.js";
import UpdateRecord from "../update-new-record/updaterecord.component.jsx";
const baseURL = switchUrls("human");

function Medicalcard({ medicaldetails, type, activationpage }) {
  const { tag_number } = useParams();
  const [showupdate, setupdate] = useState(false);
  const [showdelete, setdelete] = useState(false);

  function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1, c.length);

      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  }


  const handeldelete = async (type) => {
    try {
      const {data} = await axios.post(`${baseURL}/api/human/deleteMedicalDetails`,{type,medicaldetails,tag_number}, {
        headers: { Authorization: readCookie("token") },
      })
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  };

  function capitalize(str) {
    if (str) {
      const word = str.charAt(0).toUpperCase() + str.slice(1);

      return word;
    }
  }
  const convertISOStringToMonthDay = (date) => {
    const tempDate = new Date(date).toString().split(" ");
    const formattedDate = `${tempDate[2]} ${tempDate[1]}, ${tempDate[3]}`;

    return formattedDate;
  };
  return (
    <>
      <div className="cards-component-wrapper">
        <div className="cards-component-container">
          {type === "vaccine" ? (
            <>
              <div className="left-side-content cards-component-container-head">
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/vaccinated.png"
                  }
                  alt="vaccination"
                />
                <h5 className="record-name">
                  {capitalize(medicaldetails?.Vaccination_name)}
                </h5>
                <div className="tag-number">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "20px", height: "20px" }}
                    alt="qr"
                  />
                  <p className="mx-1">{tag_number} </p>
                </div>
                <div className={`cards-component-main-wrapper mobile-view`}>
                  <div className="cards-component-main-content">
                    <label>Vaccinated on</label>

                    <h5>
                      {convertISOStringToMonthDay(
                        medicaldetails?.VaccinationAdministration_date
                      )}
                    </h5>
                  </div>

                  {medicaldetails?.vaccinedoctor_firstname ? (
                    <div className="cards-component-main-content">
                      <label>Vaccinated by</label>

                      <h5>
                        Dr {medicaldetails?.vaccinedoctor_firstname}{" "}
                        {medicaldetails?.vaccinedoctor_lastname}
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {medicaldetails?.VaccinationNotes ? (
                    <div className="cards-component-main-content reminder-notes">
                      <label>Notes</label>

                      <h6>{medicaldetails?.VaccinationNotes}</h6>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="middle-content">
                <div className="cards-component-main-wrapper-flex">
                  <div className={`cards-component-main-wrapper laptop-view`}>
                    <div className="cards-component-main-content">
                      <label>Vaccinated Date</label>

                      <h5>
                        {convertISOStringToMonthDay(
                          medicaldetails?.VaccinationAdministration_date
                        )}
                      </h5>
                    </div>

                    {medicaldetails?.vaccinedoctor_firstname ? (
                      <div className="cards-component-main-content">
                        <label>Vaccinated By</label>

                        <h5>
                          Dr {medicaldetails?.vaccinedoctor_firstname}{" "}
                          {medicaldetails?.vaccinedoctor_lastname}
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}
                    {medicaldetails?.VaccinationNotes ? (
                      <div className="cards-component-main-content reminder-notes">
                        <label>Notes</label>

                        <h6>{medicaldetails?.VaccinationNotes}</h6>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {type === "Allergy" ? (
            <>
              <div className="cards-component-container-head">
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/allergy.png"
                  }
                  alt="allergy"
                />
                <h5 className="record-name">
                  {capitalize(medicaldetails?.Allergy_name)}
                </h5>
                <div className="tag-number">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "20px", height: "20px" }}
                    alt="qr"
                  />
                  <span className="mx-1">{tag_number} </span>
                </div>
                <div className={`cards-component-main-wrapper mobile-view`}>
                  <div className="cards-component-main-content">
                    <label>Diagnosed on</label>

                    <h5>
                      {convertISOStringToMonthDay(
                        medicaldetails?.AllergyDiagnosis_date
                      )}
                    </h5>
                  </div>

                  {medicaldetails?.allergydoctor_firstname ? (
                    <div className="cards-component-main-content">
                      <label>Diagnosed by:</label>

                      <h5>
                        Dr {medicaldetails?.allergydoctor_firstname}{" "}
                        {medicaldetails?.allergydoctor_lastname}
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {medicaldetails?.AllergyNotes ? (
                    <div className="cards-component-main-content">
                      <label>Notes</label>

                      <h6>{medicaldetails?.AllergyNotes}</h6>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="middle-content">
                <div className="cards-component-main-wrapper-flex">
                  <div className={`cards-component-main-wrapper laptop-view`}>
                    <div className="cards-component-main-content">
                      <label>Diagnosed on</label>

                      <h5>
                        {convertISOStringToMonthDay(
                          medicaldetails?.AllergyDiagnosis_date
                        )}
                      </h5>
                    </div>

                    {medicaldetails?.allergydoctor_firstname ? (
                      <div className="cards-component-main-content">
                        <label>Diagnosed by:</label>

                        <h5>
                          Dr {medicaldetails?.allergydoctor_firstname}{" "}
                          {medicaldetails?.allergydoctor_lastname}
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}
                    {medicaldetails?.AllergyNotes ? (
                      <div className="cards-component-main-content">
                        <label>Notes</label>

                        <h6>{medicaldetails?.AllergyNotes}</h6>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {type === "Surgery" ? (
            <>
              <div className="left-side-content cards-component-container-head">
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/surgery.png"
                  }
                  alt="surgery"
                />
                <h5 className="record-name">
                  {capitalize(medicaldetails?.Surgery_name)}
                </h5>
                <div className="tag-number">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "20px", height: "20px" }}
                    alt="qr"
                  />
                  <span className="mx-1">{tag_number} </span>
                </div>
                <div className={`cards-component-main-wrapper mobile-view`}>
                  <div className="cards-component-main-content">
                    <label>Performed on</label>

                    <h5>
                      {convertISOStringToMonthDay(medicaldetails?.Surgery_date)}
                    </h5>
                  </div>

                  {medicaldetails?.surgerydoctor_firstname ? (
                    <div className="cards-component-main-content">
                      <label>Performed by</label>

                      <h5>
                        Dr {medicaldetails?.surgerydoctor_firstname}{" "}
                        {medicaldetails?.surgerydoctor_lastname}
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {medicaldetails?.SurgeryNotes ? (
                    <div className="cards-component-main-content reminder-notes">
                      <label>Notes</label>

                      <h6>{medicaldetails?.SurgeryNotes}</h6>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* middle content */}
              <div className="middle-content">
                <div className="cards-component-main-wrapper-flex">
                  <div className={`cards-component-main-wrapper laptop-view`}>
                    <div className="cards-component-main-content">
                      <label>Performed on</label>

                      <h5>
                        {convertISOStringToMonthDay(
                          medicaldetails?.Surgery_date
                        )}
                      </h5>
                    </div>

                    {medicaldetails?.surgerydoctor_firstname ? (
                      <div className="cards-component-main-content">
                        <label>Performed by</label>
                        <h5>
                          Dr {medicaldetails?.surgerydoctor_firstname}{" "}
                          {medicaldetails?.surgerydoctor_lastname}
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}
                    {medicaldetails?.SurgeryNotes ? (
                      <div className="cards-component-main-content reminder-notes">
                        <label>Notes</label>

                        <h6>{medicaldetails?.SurgeryNotes}</h6>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {type === "Medication" ? (
            <>
              <div className="cards-component-container-head">
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medication.png"
                  }
                  alt="medication"
                />
                <h5 className="record-name">
                  {capitalize(medicaldetails?.Medication_name)}
                </h5>
                <div className="tag-number">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "20px", height: "20px" }}
                    alt="qr"
                  />
                  <p className="mx-1">{tag_number} </p>
                </div>
                <div className={`cards-component-main-wrapper mobile-view`}>
                  <div className="cards-component-main-content">
                    <label>Start date</label>

                    <h5>
                      {convertISOStringToMonthDay(
                        medicaldetails?.Medication_startdate
                      )}
                    </h5>
                  </div>

                  {medicaldetails?.medicinedoctor_firstname ? (
                    <div className="cards-component-main-content">
                      <label>Medicated by</label>

                      <h5>
                        Dr {medicaldetails?.medicinedoctor_firstname}{" "}
                        {medicaldetails?.medicinedoctor_lastname}
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {medicaldetails?.MedicationNotes ? (
                    <div className="cards-component-main-content reminder-notes">
                      <label>Notes</label>

                      <h6>{medicaldetails?.MedicationNotes}</h6>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="cards-component-main-wrapper">
                    <div className="cards-component-main-content">
                      <label>End Date</label>
                      {medicaldetails?.Medication_enddate ? (
                        <h5>
                          {convertISOStringToMonthDay(
                            medicaldetails?.Medication_enddate
                          )}
                        </h5>
                      ) : (
                        <h5 className="text-muted">No End Date</h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="middle-content">
                <div className="cards-component-main-wrapper-flex">
                  <div className={`cards-component-main-wrapper laptop-view`}>
                    <div className="cards-component-main-content">
                      <label>Start date</label>

                      <h5>
                        {convertISOStringToMonthDay(
                          medicaldetails?.Medication_startdate
                        )}
                      </h5>
                    </div>

                    {medicaldetails?.medicinedoctor_firstname ? (
                      <div className="cards-component-main-content">
                        <label>Medicated by</label>

                        <h5>
                          Dr {medicaldetails?.medicinedoctor_firstname}{" "}
                          {medicaldetails?.medicinedoctor_lastname}
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}
                    {medicaldetails?.MedicationNotes ? (
                      <div className="cards-component-main-content reminder-notes">
                        <label>Notes</label>

                        <h6>{medicaldetails?.MedicationNotes}</h6>
                      </div>
                    ) : (
                      ""
                    )}
                     <div className="cards-component-main-wrapper">
                    <div className="cards-component-main-content">
                      <label>End Date</label>
                      {medicaldetails?.Medication_enddate ? (
                        <h5>
                          {convertISOStringToMonthDay(
                            medicaldetails?.Medication_enddate
                          )}
                        </h5>
                      ) : (
                        <h5 className="text-muted">No End Date</h5>
                      )}
                    </div>
                  </div>
                  </div>

                 
                </div>
              </div>
            </>
          ) : null}
          {type === "Medicalcondition" ? (
            <>
              <div className="cards-component-container-head">
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medical-condition.png"
                  }
                  alt="medical condition"
                />
                <h5 className="record-name">
                  {capitalize(medicaldetails?.Medicalcondition_type)}
                </h5>
                <div className="tag-number">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                    style={{ width: "20px", height: "20px" }}
                    alt="qr"
                  />
                  <p className="mx-1">{tag_number} </p>
                </div>
                <div className={`cards-component-main-wrapper mobile-view`}>
                  <div className="cards-component-main-content">
                    <label>Diagnosed on</label>

                    <h5>
                      {convertISOStringToMonthDay(
                        medicaldetails?.MedicalconditionDiagnosed_date
                      )}
                    </h5>
                  </div>

                  {medicaldetails?.medicaldoctor_firstname ? (
                    <div className="cards-component-main-content">
                      <label>Diagnosed by</label>
                      <h5>
                        Dr {medicaldetails?.medicaldoctor_firstname}{" "}
                        {medicaldetails?.medicaldoctor_lastname}
                      </h5>
                    </div>
                  ) : (
                    ""
                  )}
                  {medicaldetails?.MedicalconditionNotes ? (
                    <div className="cards-component-main-content">
                      <label>Notes:</label>

                      <h6>{medicaldetails?.MedicalconditionNotes}</h6>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="middle-content">
            <div className="cards-component-main-wrapper-flex">
              <div className={`cards-component-main-wrapper laptop-view`}>
                <div className="cards-component-main-content">
                  <label>Diagnosed on</label>

                  <h5> {convertISOStringToMonthDay(
                          medicaldetails?.MedicalconditionDiagnosed_date
                        )}</h5>
                </div>

                {medicaldetails?.medicaldoctor_firstname ? (
                  <div className="cards-component-main-content">
                    <label>Diagnosed by</label>

                    <h5>
                          Dr {medicaldetails?.medicaldoctor_firstname}{" "}
                          {medicaldetails?.medicaldoctor_lastname}
                        </h5>
                  </div>
                ) : (
                  ""
                )}
                {medicaldetails?.MedicalconditionNotes ? (
                  <div className="cards-component-main-content">
                    <label>Notes:</label>

                    <h6>{medicaldetails?.MedicalconditionNotes}</h6>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

            
            </>
          ) : null}
          {
            type === 'Measurement' ? <>
               <div className="cards-component-container-head">
            <img
              src={
                "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/measure.png"
              }
              alt="measurement"
            />
              <h5> Height : {medicaldetails?.Height}cm</h5>
            <h5> Weight : {medicaldetails?.Weight}kg</h5>
            <div className="tag-number">
              <img
                src="https://storage.googleapis.com/pettag/qr/assets/qrcode.png"
                style={{ width: "20px", height: "20px" }}
                alt="qr"
              />
              <p className="mx-1">{tag_number} </p>
            </div>
            <div className={`cards-component-main-wrapper mobile-view`}>
              <div className="cards-component-main-content">
                <label>Measured on</label>

                <h5>{convertISOStringToMonthDay(medicaldetails?.Measurement_date)}</h5>
              </div>

              {medicaldetails?.Measuredby ? (
                <div className="cards-component-main-content">
                  <label>Measured by</label>

                  <h5>{medicaldetails?.Measuredby}</h5>
                </div>
              ) : (
                ""
              )}
              {medicaldetails?.MeasurementNotes ? (


                <div className="cards-component-main-content">
                  <label>Notes</label>

                  <h6>{medicaldetails?.MeasurementNotes}</h6>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="middle-content">
            <div className="cards-component-main-wrapper-flex">
              <div className={`cards-component-main-wrapper laptop-view`}>
                <div className="cards-component-main-content">
                  <label>Measured on</label>

                  <h5>{convertISOStringToMonthDay(medicaldetails?.Measurement_date)}</h5>
                </div>

                {medicaldetails?.Measuredby ? (
                  <div className="cards-component-main-content">
                    <label>Measured by</label>

                    <h5>{medicaldetails?.Measuredby}</h5>
                  </div>
                ) : (
                  ""
                )}
                {medicaldetails?.MeasurementNotes ? (
                  <div className="cards-component-main-content">
                    <label>Notes</label>

                    <h6>{medicaldetails?.MeasurementNotes}</h6>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
            </> : null
          }
          {!activationpage ? (
            <div className="right">
              <div className="cards-component-kebab-menu">
                <Tooltip title="Edit Record">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/edits.png"
                    className="edit-btn"
                    alt="edit"
                    onClick={() => {
                      setupdate(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Delete Record">
                  <img
                    src="https://storage.googleapis.com/pettag/qr/assets/delete.png"
                    className="delete-btn"
                    alt="del"
                    onClick={() => {
                      setdelete(true);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          ) : null}

          <Dialog
            PaperProps={{
              style: { borderRadius: 15, maxWidth: "835px" },
            }}
            open={showupdate}
            aria-labelledby="form-dialog-title"
          >
            <UpdateRecord
              medicaldetails={medicaldetails}
              type={type}
              showupdate={setupdate}
            />
          </Dialog>
        </div>
      </div>

      <Dialog
        Papermedicaldetails={{
          style: { borderRadius: 15 },
        }}
        open={showdelete}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>
          {`Are you sure you want to delete this ${type} card?`}
        </DialogTitle>

        <div className="cards-component-delete-dialog">
          <button onClick={()=>handeldelete(type)}>Confirm</button>

          <button onClick={() => setdelete(false)}>Cancel</button>
        </div>
      </Dialog>
    </>
  );
}

export default Medicalcard;
