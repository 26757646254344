import React from "react";
import "./editguardiandetails.component.css";
// import CatDog from "../../assets/user2.png";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "font-awesome/css/font-awesome.min.css";
import $ from "jquery";
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import axios from "axios";
import Navbar from "../header/header.component.jsx";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import { switchUrls } from "../../api/index.js";
import {
  Breadcrumbs,
  FormControl,
  FormLabel,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MuiPhoneInput from "material-ui-phone-number";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import { Country, State, City } from "country-state-city";
import Loader from "../../assets/loader.gif";

const baseURL = switchUrls("human");

const RadioButton = withStyles({
  //nishant edits
  root: {
    color: purple[900],
    "&$checked": {
      color: purple[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];

    while (c.charAt(0) === " ") c = c.substring(1, c.length);

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }

  return null;
}

let usertoken = readCookie("token");

export default class EditguardianDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageUrl: null,

      tag_number: this.props.match.params.tag_number,
      typeof_guardian: "",
      guardian_name: "",
      guardian_blood_group: "",
      guardian_gender: "",
      guardian_date_of_birth: "",
      guardian_age: "",
      guardian_identity_mark: "",
      guardian_skin_colour: "",
      guradian_email: "",
      guardian_alternate_email: "",
      guradian_mobile: "",
      guardian_alternate_mobile: "",
      guardian_country: "",
      guardian_state: "",
      guardian_city: "",
      guardian_pin: "",
      guardian_address1: "",
      guardian_address2: "",
      guardian_code: "",
      guardian_alternatecode: "",
      message: "",
      redirecttomedicalpage: false,
      key_imagelocation: null,
      sidebar: true,
      subMenu: true,
      showLoader: true,
      guardian_firstname:"",
      guardian_lastname : "",
      
    };

    this.handleChange = this.handleChange.bind(this);
    this.showSidebar = this.showSidebar.bind(this);
    this.showSubMenu = this.showSubMenu.bind(this);
  }

  componentDidMount() {
    $("#profile-image-uploadActi").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    $("#profile-image-uploadActi-1").on("click", function () {
      $("#choose-profile-imageActi").trigger("click");
    });

    axios
      .get(
        `${baseURL}/api/human/getguardiandetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        console.log("response: ", response);
        this.setState({
          uid: response.data.uid,
          guardian_name: response?.data?.guardian_name || "",
          guardian_mobile: response?.data?.guardian_mobile || "",
          guardian_alternate_mobile: response?.data?.guardian_alternate_mobile,
          guardian_email: response?.data?.guardian_email,
          guardian_alternate_email: response?.data?.guardian_alternate_email,
          guardian_country: response?.data?.guardian_country,
          guardian_address1: response?.data?.guardian_address1,
          guardian_address2: response?.data?.guardian_address2,
          guardian_state: response?.data?.guardian_state,
          guardian_city: response?.data?.guardian_city,
          guardian_pin: response?.data?.guardian_pin,
          guardian_gender: response?.data?.guardian_gender,
          guardian_identity_mark: response?.data?.guardian_identity_mark,
          guardian_code: response?.data?.guardian_code,
          guardian_alternatecode: response?.data?.guardian_alternatecode,
          guardian_firstname:response?.data?.guardian_firstname,
          guardian_lastname : response?.data?.guardian_lastname,
          typeof_guardian:response?.data?.typeof_guardian
        });
        setTimeout(() => {
          this.setState({ showLoader: false });
        }, 1000);
      })
      .catch((error) => {
        console.error(error.message);
      });

    axios
      .get(
        `${baseURL}/api/pet/countMedicalDetails/` +
          this.props.match.params.tag_number
      )
      .then((response) => {
        let count = response.data;
        if (
          count.Vaccination === 0 &&
          count.Allergy === 0 &&
          count.Surgery === 0 &&
          count.Medication === 0 &&
          count.Medicalcondition === 0 &&
          count.Measurement === 0
        ) {
          this.setState({ redirecttomedicalpage: true });
        } else {
          this.setState({ redirecttomedicalpage: false });
        }
      });
  }

  handleChange(event) {
    if (event.target.files[0]) {
      this.setState({ message: "" });
      this.setState({
        selectedImage: event.target.files[0],
        imageUrl: URL.createObjectURL(event.target.files[0]),
      });
    }
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  changeHandler = (e) => {
    // console.log("event: ", e);
    this.setState({ [e.target.name]: e.target.value });
  };

  phoneNumberChangeHandler = (value) => {
    // console.log("value: ",value);
    const countryCodeRegex = /^\+[0-9]+/;
    const phoneNumberRegex = /(?<=^\+)[0-9\s()-]+(?=\s|$)/;

    const extractedCountryCode = value.match(countryCodeRegex);
    const extractedPhoneNumber = value.match(phoneNumberRegex);

    if (extractedCountryCode && extractedPhoneNumber) {
      const countryCode = extractedCountryCode[0].replace(/\s/g, ""); // Update the regex to remove spaces
      let phoneNumberWithCountryCode = extractedPhoneNumber.input
        .replace(/\s/g, "")
        .trim();

      // Special case for +16648879871678
      if (phoneNumberWithCountryCode === countryCode) {
        console.log("Special case: Phone number is same as country code");
        this.setState({ guardian_mobile: "", guardian_code: countryCode });
      } else if (phoneNumberWithCountryCode.startsWith(countryCode)) {
        phoneNumberWithCountryCode = phoneNumberWithCountryCode.substring(
          countryCode.length
        );
        this.setState({
          guardian_mobile: phoneNumberWithCountryCode,
          guardian_code: countryCode,
        });
      } else {
        this.setState({
          guardian_mobile: phoneNumberWithCountryCode,
          guardian_code: countryCode,
        });
      }
    }
  };
  alternatePhoneNumberChangeHandler = (value) => {
    // console.log("value: ",value);
    const countryCodeRegex = /^\+[0-9]+/;
    const phoneNumberRegex = /(?<=^\+)[0-9\s()-]+(?=\s|$)/;

    const extractedCountryCode = value.match(countryCodeRegex);
    const extractedPhoneNumber = value.match(phoneNumberRegex);

    if (extractedCountryCode && extractedPhoneNumber) {
      const countryCode = extractedCountryCode[0].replace(/\s/g, ""); // Update the regex to remove spaces
      let phoneNumberWithCountryCode = extractedPhoneNumber.input
        .replace(/\s/g, "")
        .trim();

      // Special case for +16648879871678
      if (phoneNumberWithCountryCode === countryCode) {
        console.log("Special case: Phone number is same as country code");
        this.setState({
          guardian_alternate_mobile: "",
          guardian_alternatecode: countryCode,
        });
      } else if (phoneNumberWithCountryCode.startsWith(countryCode)) {
        phoneNumberWithCountryCode = phoneNumberWithCountryCode.substring(
          countryCode.length
        );
        this.setState({
          guardian_alternate_mobile: phoneNumberWithCountryCode,
          guardian_alternatecode: countryCode,
        });
      } else {
        this.setState({
          parent_altphone: phoneNumberWithCountryCode,
          guardian_alternatecode: countryCode,
        });
      }
    }
  };



  submitHandler = (e) => {
    e.preventDefault();

    axios
      .post(
        `${baseURL}/api/human/guardiandetails/` +
          this.props.match.params.tag_number,
        this.state,
        { headers: { Authorization: usertoken } }
      )
      .then((response) => {
        if (response.data === "guardian Details Updated") {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "guardian Details Updated",
          });

          window.location.href = "/dashboard/" + localStorage.getItem("uid");

          const fd = new FormData();

          fd.append("image", this.state.selectedImage);
          fd.append("tag_number", this.props.match.params.tag_number);

         /* axios
            .post(`${baseURL}/api/pet/parentimage-upload`, fd)
            .then((res) => {
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Pet Parent Details Updated",
              });

              this.state.redirecttomedicalpage
                ? (window.location.href =
                    "/#/medical-dashboard/" +
                    this.props.match.params.tag_number)
                : (window.location.href =
                    "/dashboard/" + localStorage.getItem("uid"));
            })
            .catch((error) => {
              console.error(error.message);
            });*/
        } else if (response.data === "wrong data") {
          this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
        }
      })
      .catch((error) => {
        console.error(error.message);

        this.setState({ snackbaropen: true, snackbarmsg: "Unsuccessful" });
      });
  };

  showSidebar(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      sidebar: !currentState.sidebar,
    }));
  }

  showSubMenu(e) {
    e.preventDefault();

    this.setState((currentState) => ({
      subMenu: !currentState.subMenu,
    }));
  }

  // Breadcrumb handle click
  swithBreadcrumbs = (e, tab) => {
    if (tab === "HUMAN_DETAILS") {
      window.location.href =
        "/#/human-details/" + this.props.match.params.tag_number;
    }
  };

  render() {
    const tagn = this.props.match.params.tag_number;

    const {
      sidebar,
      subMenu,
      key_imagelocation,
      parent_imagelocation,
      imageUrl,
      uid,
      tag_number,
      guardian_name,
      guardian_blood_group,
      guardian_gender,
      guardian_date_of_birth,
      guardian_age,
      guardian_identity_mark,
      guardian_skin_colour,
      guardian_email,
      guardian_alternate_email,
      guardian_mobile,
      guardian_alternate_mobile,
      guardian_country,
      guardian_state,
      guardian_city,
      guardian_pin,
      guardian_address1,
      guardian_address2,
      guardian_code,
      guardian_alternatecode,
      typeof_guardian,
      guardian_firstname,
      guardian_lastname,
    } = this.state;

    let capFirstLetter = (sentenses) => {
      const arr = sentenses?.split(" ");

      for (let i = 0; i < arr?.length; i++) {
        arr[i] = arr[i]?.charAt(0).toUpperCase() + arr[i]?.slice(1);
      }
      return arr?.join(" ");
    };

    let countryNameCapFirstLetter = capFirstLetter(guardian_country);

    const countryWithIsoCode = Country?.getAllCountries().filter((v) => {
      return v.name === countryNameCapFirstLetter;
    });

    let stateNameCapFirstLetter = capFirstLetter(guardian_state);
    let getStateIsoCode = State?.getStatesOfCountry(
      countryWithIsoCode[0]?.isoCode
    ).filter((v) => {
      return v.name === stateNameCapFirstLetter;
    });
    // console.log("getStateIsoCode: ", getStateIsoCode[0]);

    document.onreadystatechange = () => {
      this.setState({ showLoader: true });
      if (document.readyState === "complete") {
        this.setState({ showLoader: false });
      }
    };

    return (
      <>
        <Navbar />

        <div className="editParentDetails-main-wrapper">
          <div className="editParentDetails-left-wrapper">
            <div className="editParentDetails-sidebar-toggle-btn-wrapper">
              <button
                className="editParentDetails-sidebar-toggle-btn"
                onClick={this.showSidebar}
              >
                {sidebar ? (
                  <BsIcons.BsLayoutSidebarInset />
                ) : (
                  <BsIcons.BsLayoutSidebarInsetReverse />
                )}
              </button>
            </div>

            <div
              className={
                sidebar
                  ? "editParentDetails-sidebar-container-hide"
                  : "editParentDetails-sidebar-container"
              }
            >
              <button
                className="editParentDetails-sidebar-menu-link"
                onClick={this.showSubMenu}
              >
                MY TAGS{" "}
                {subMenu ? (
                  <AiIcons.AiFillCaretDown />
                ) : (
                  <AiIcons.AiFillCaretUp />
                )}
              </button>

              <ul
                className={
                  subMenu
                    ? "editParentDetails-sidebar-menu-ul-hide"
                    : "editParentDetails-sidebar-menu-ul"
                }
              >
                <li>
                  <button
                    className="editParentDetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    All Tags
                  </button>
                </li>

                <li>
                  <button
                    className="editParentDetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    Active | Inactive Tags
                  </button>
                </li>

                <li>
                  <button
                    className="editParentDetails-sidebar-menu-ul-links"
                    onClick={() => {
                      window.location.href =
                        "/dashboard/" + this.props.match.params.tag_number;
                    }}
                  >
                    Lost | Found Tags
                  </button>
                </li>
              </ul>

             

              <button
                className="editParentDetails-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/human/medical-dashboard/" +
                    this.props.match.params.tag_number;
                }}
              >
                MEDICAL DETAILS
              </button>
              <button
                className="editParentDetails-sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/human-details/" +
                    this.props.match.params.tag_number;
                }}
              >
                HUMAN DETAILS
              </button>

             
            </div>
          </div>

          <div className="editParentDetails-right-wrapper">
          
            <div className="petParentDetails ">
              <p align="center">
                <strong>Guardian Details</strong>
              </p>
              <div className="editParentDetails-form-container">
                <div className="editParentDetails-form-container-bottom">
                  <form
                    className="editParentDetails-form"
                    onSubmit={this.submitHandler}
                  >
                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                        <TextField
                            label="Guardian Firstname"
                          variant="outlined"
                          type="text"
                          name="guardian_firstname"
                          value={guardian_firstname}
                          onChange={this.changeHandler}
                          required
                        />
                      </div>
                      <div className="editParentDetails-input-wrapper">
                      <div className="editParentDetails-input-wrapper">
                          <TextField
                            label="Guardian Lastname"
                            variant="outlined"
                            type="text"
                            name="guardian_lastname"
                            value={guardian_lastname}
                            onChange={this.changeHandler}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                      <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            name="guardian_email"
                            value={guardian_email}
                            onChange={this.changeHandler}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            required
                          />
                      </div>
                      <div className="editParentDetails-input-wrapper">
                      <TextField
                            select
                            label="Relation"
                            type="text"
                            variant="outlined"
                            name="typeof_guardian"
                            value={typeof_guardian}
                            onChange={this.changeHandler}
                            required
                          >
                            <MenuItem value="Son">Son</MenuItem>
                            <MenuItem value="Daughter">Daughter</MenuItem>
                            <MenuItem value="Father">Father</MenuItem>
                            <MenuItem value="Mother">Mother</MenuItem>
                            <MenuItem value="GrandFather">GrandFather</MenuItem>
                            <MenuItem value="GrandMother">GrandMother</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </TextField>
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                      <TextField
                            label="Alternate Email"
                            type="email"
                            variant="outlined"
                            name="guardian_alternate_email"
                            required
                            value={guardian_alternate_email}
                            onChange={this.changeHandler}
                          />
                      </div>
                      <div className="editParentDetails-input-wrapper">
                      <MuiPhoneInput
                            label="Number"
                            defaultCountry="in"
                            value={`+${guardian_code} ${guardian_mobile}`}
                            onChange={this.phoneNumberChangeHandler}
                            required
                            regions={[
                              "america",
                              "caribbean",
                              "asia",
                              "middle-east",
                              "oceania",
                              "europe",
                              "africa",
                            ]}
                            name="guardian_mobile"
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  edge="end"
                                  aria-label="personal-details-icon"
                                >
                                  <ContactPhoneIcon />
                                </IconButton>
                              ),
                            }}
                          />
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                      <MuiPhoneInput
                            label="Alternate Number"
                            defaultCountry="in"
                            value={`+${guardian_alternatecode} ${guardian_alternate_mobile}`}
                            onChange={this.alternatePhoneNumberChangeHandler}
                            regions={[
                              "america",
                              "caribbean",
                              "asia",
                              "middle-east",
                              "oceania",
                              "europe",
                              "africa",
                            ]}
                            variant="outlined"
                            name="guardian_alternate_mobile"
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  edge="end"
                                  aria-label="personal-details-icon"
                                >
                                  <ContactPhoneIcon />
                                </IconButton>
                              ),
                            }}
                          />
                      </div>
                      <div className="editParentDetails-input-wrapper">
                      <TextField
                            select
                            label="Gender"
                            type="text"
                            variant="outlined"
                            maxLength="3"
                            name="guardian_gender"
                            value={guardian_gender}
                            onChange={this.changeHandler}
                            required
                          >
                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </TextField>
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                      <FormControl>
                            <InputLabel
                              id="demo-simple-select-label"
                              style={{ marginTop: "-7px", marginLeft: "13px" }}
                            >
                              Country
                            </InputLabel>
                            <Select
                              variant="outlined"
                              label="Country"
                              name="guardian_country"
                              required
                              value={
                                guardian_country
                                  ?.charAt(0)
                                  .toUpperCase() +
                                guardian_country?.slice(1)
                              }
                              onChange={this.changeHandler}
                            >
                              {Country?.getAllCountries()?.map((c) => {
                                return (
                                  <MenuItem key={c.isoCode} value={c.name}>
                                    {c.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                      </div>
                      <div className="editParentDetails-input-wrapper">
                      <FormControl>
                            <InputLabel
                              id="demo-simple-select-label"
                              style={{ marginTop: "-7px", marginLeft: "13px" }}
                            >
                              State
                            </InputLabel>
                            <Select
                              disabled={
                                guardian_country ? false : true
                              }
                              variant="outlined"
                              label="State"
                              name="guardian_state"
                              required
                              value={
                                guardian_state
                                  ?.charAt(0)
                                  .toUpperCase() +
                                guardian_state?.slice(1)
                              }
                              onChange={this.changeHandler}
                            >
                              {State?.getStatesOfCountry(
                                countryWithIsoCode[0]?.isoCode
                              )?.map((s) => {
                                return (
                                  <MenuItem key={s.isoCode} value={s.name}>
                                    {s.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                      </div>
                    </div>

                    <div className="two-field-wrapper">
                      <div className="editParentDetails-input-wrapper">
                      <FormControl>
                            <InputLabel
                              id="demo-simple-select-label"
                              style={{ marginTop: "-7px", marginLeft: "13px" }}
                            >
                              City
                            </InputLabel>
                            <Select
                              variant="outlined"
                              disabled={guardian_state ? false : true}
                              label="City"
                              required
                              name="guardian_city"
                              value={
                                guardian_city
                                  ?.charAt(0)
                                  .toUpperCase() +
                                guardian_city?.slice(1)
                              }
                              onChange={this.changeHandler}
                            >
                              {City.getCitiesOfState(
                                countryWithIsoCode[0]?.isoCode,
                                getStateIsoCode[0]?.isoCode
                              )?.map((ct) => {
                                return (
                                  <MenuItem key={ct.isoCode} value={ct.name}>
                                    {ct.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                      </div>
                      <div className="editParentDetails-input-wrapper">
                      <TextField
                            label="Zip/Pin Code"
                            type="text"
                            variant="outlined"
                            // className="editParentDetails-input-tag"
                            name="guardian_pin"
                            value={guardian_pin}
                            required
                            onChange={this.changeHandler}
                          />
                      </div>
                    </div>
                    <div className="two-field-wrapper">
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            label="Address 1"
                            type="text"
                            variant="outlined"
                            name="guardian_address1"
                            value={guardian_address1}
                            onChange={this.changeHandler}
                            required
                          />
                        </div>
                        <div className="editParentDetails-input-wrapper">
                          <TextField
                            label="Address 2"
                            type="text"
                            variant="outlined"
                            name="guardian_address2"
                            value={guardian_address2}
                            onChange={this.changeHandler}
                          />
                        </div>
                      </div>

                    <div className="two-field-wrapper"></div>
                    {/*  */}
                    <div className="editParentDetails-btn-row">
                      <Button
                        variant="contained"
                        type="submit"
                        className="submit-btn-editPet"
                      >
                        {/* {this.state.redirecttomedicalpage ? "NEXT" : "SUBMIT"} */}
                        SUBMIT
                      </Button>

                      <Button
                        variant="contained"
                        className="cancel-btn-editPet"
                        type="button"
                        onClick={() => {
                          window.location.href =
                            "/dashboard/" + localStorage.getItem("uid");
                        }}
                      >
                        cancel
                      </Button>
                    </div>

                    {this.state.showLoader && (
                      <div className="loader-container">
                        <div className="loader">
                          <img
                            src={Loader}
                            alt="Loading..."
                            loading="lazy"
                            style={{ width: "100px", height: "100px" }}
                          />
                        </div>
                      </div>
                    )}

                    <Snackbar
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      open={this.state.snackbaropen}
                      autoHideDuration={8000}
                      onClose={this.snackbarClose}
                      message={this.state.snackbarmsg}
                      action={[
                        <IconButton
                          arial-label="Close"
                          color="inherit"
                          onClick={this.snackbarClose}
                        >
                          X
                        </IconButton>,
                      ]}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
