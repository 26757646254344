import React, { useEffect, useState } from "react";
import "../medicaldashboard/medicaldashboard.component.css";
import Navbar from "../header/header.component";
import * as BsIcons from "react-icons/bs";
import Button from "@material-ui/core/Button";
import * as AiIcons from "react-icons/ai";
import { Fab, Tooltip } from "@mui/material";
import Dialog from "@material-ui/core/Dialog";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { switchUrls } from "../../api/index.js";
import axios from "axios";
import { useParams } from "react-router-dom";

import AddNewRecord from "./add-new-record/addnewrecord.component.jsx";
import Medicalcard from "./cards/Medicalcard.jsx";
const baseURL = switchUrls("human");

function MedicalDashboard() {
  const { tag_number } = useParams();
  const [sidebar, setsidebar] = useState(true);
  const [subMenu, setsubmenu] = useState(true);
  const [isFloatingBtn, setisfloating] = useState(true);
  const [showSearchInput, setsearchinput] = useState(false);
  const [addnewrec, setnewrec] = useState(false);
  const [searchkey, setsearchkey] = useState("");
  const [medicalcount, setmedicalcount] = useState({});
  const [defaultmsg, showdefaultmsg] = useState(false);
  const [medicaldetails, setmedicaldetails] = useState([]);
  const [searchresult, setsearchresult] = useState([]);

  const handelsearchinput = (e) => {
    try {
      setsearchkey(e.target.value);
    } catch (error) {}
  };

  const getmedicalcount = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/human/countMedicalDetails/` + tag_number
      );
      console.log(data);
      if (
        data?.Vaccination === 0 &&
        data?.Allergy === 0 &&
        data?.Surgery === 0 &&
        data?.Medication === 0 &&
        data?.Medicalcondition === 0 &&
        data?.Measurement === 0
      ) {
        showdefaultmsg(true);
      }
      setmedicalcount(data);
    } catch (error) {
      console.log(error);
    }
  };
  const getmedicaldetails = async () => {
    try {
      const { data } = await axios.get(
        `${baseURL}/api/human/getMedicalDetails/` + tag_number
      );
      console.log(data);
      setmedicaldetails(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!searchkey.length) {
      getmedicalcount();
      getmedicaldetails();
    } else {
      const getsearch = setTimeout(() => {
        axios
          .post(`${baseURL}/api/human/search/`, {
            key: searchkey,
            tag_number,
          })
          .then(({ data }) => {
            setmedicaldetails(data);
          });
      }, 1000);
      return () => clearTimeout(getsearch);
    }
  }, [searchkey]);
  return (
    <>
      <Navbar />
      <div className="main-wrapper">
        <div className="left-wrapper">
          <div className="sidebar-toggle-btn-wrapper">
            <button
              className="sidebar-toggle-btn"
              onClick={() => setsidebar(!sidebar)}
            >
              {sidebar ? (
                <BsIcons.BsLayoutSidebarInset />
              ) : (
                <BsIcons.BsLayoutSidebarInsetReverse />
              )}
            </button>
          </div>

          <div
            className={sidebar ? "sidebar-container-hide" : "sidebar-container"}
          >
            <button
              className="sidebar-menu-link"
              onClick={() => setsubmenu(!subMenu)}
            >
              MY TAGS{" "}
              {subMenu ? (
                <AiIcons.AiFillCaretDown />
              ) : (
                <AiIcons.AiFillCaretUp />
              )}
            </button>

            <ul
              className={subMenu ? "sidebar-menu-ul-hide" : "sidebar-menu-ul"}
            >
              <li>
                <button
                  className="sidebar-menu-ul-links"
                  onClick={() => {
                    window.location.href = "/dashboard/" + tag_number;
                  }}
                >
                  All Tags
                </button>
              </li>

              <li>
                <button
                  className="sidebar-menu-ul-links"
                  onClick={() => {
                    window.location.href = "/dashboard/" + tag_number;
                  }}
                >
                  Active | Inactive Tags
                </button>
              </li>

              <li>
                <button
                  className="sidebar-menu-ul-links"
                  onClick={() => {
                    window.location.href = "/dashboard/" + tag_number;
                  }}
                >
                  Lost | Found Tags
                </button>
              </li>
            </ul>

            <button
              className="sidebar-menu-link"
              onClick={() => {
                window.location.href = "/#/guardian-details/" + tag_number;
              }}
            >
              GUARDIAN DETAILS
            </button>

            <button
              className="sidebar-menu-link"
              onClick={() => {
                window.location.href = "/#/human-details/" + tag_number;
              }}
            >
              HUMAN DETAILS
            </button>

            {/* <button
                className="sidebar-menu-link"
                onClick={() => {
                  window.location.href =
                    "/#/medical-dashboard/" +
                    this.props.match.params.tag_number;
                }}
              >
                PET MEDICAL DETAILS
              </button> */}
          </div>
        </div>
        <div className="medicalDashboard-right-wrapper">
          <div
            className="row text-center"
            style={{
              margin: "auto",
              textAlign: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div className="rightside-blocks mt-3">
              <div>
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/vaccinated.png"
                  }
                  width="25px"
                  height="25px"
                  className="rightside-blocks-image"
                  alt="vaccination"
                />

                <span className="ml-2 rightside-vaccinations">
                  Vaccinations
                </span>
              </div>
              <p className="text-center rightside-blocks-middle">
                {medicalcount?.Vaccination}
              </p>
            </div>

            <div className="rightside-blocks mt-3">
              <div>
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/allergy.png"
                  }
                  width="25px"
                  height="25px"
                  className="rightside-blocks-image"
                  alt="allergy"
                />
                <span className="ml-2 rightside-vaccinations">Allergies</span>
              </div>

              <p className="text-center rightside-blocks-middle">
                {medicalcount?.Allergy}
              </p>
            </div>

            <div className="rightside-blocks mt-3">
              <div>
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/surgery.png"
                  }
                  width="25px"
                  height="25px"
                  className="rightside-blocks-image"
                  alt="surgery"
                />
                <span className="ml-2 rightside-vaccinations">Surgeries</span>
              </div>

              <p className="text-center rightside-blocks-middle">
                {medicalcount?.Surgery}
              </p>
            </div>

            <div className="rightside-blocks mt-3">
              <div>
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medication.png"
                  }
                  width="25px"
                  height="25px"
                  className="rightside-blocks-image"
                  alt="medication"
                />
                <span className="ml-2 rightside-vaccinations">Medication</span>
              </div>

              <p className="text-center rightside-blocks-middle">
                {medicalcount?.Medication}
              </p>
            </div>

            <div className="rightside-blocks mt-3">
              <div>
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/medical-condition.png"
                  }
                  width="25px"
                  height="25px"
                  className="rightside-blocks-image"
                  alt="medical condition"
                />
                <span className="ml-2 rightside-vaccinations">
                  Medical Condition
                </span>
              </div>

              <p className="text-center rightside-blocks-middle med_con ">
                {medicalcount?.Medicalcondition}
              </p>
            </div>

            <div className="rightside-blocks mt-3">
              <div>
                <img
                  src={
                    "https://storage.googleapis.com/pettag/qr/assets/pet-medical-vector/measure.png"
                  }
                  width="25px"
                  height="25px"
                  className="rightside-blocks-image"
                  alt="measurement"
                />
                <span className="ml-2 rightside-vaccinations">Measurement</span>
              </div>
              <p className="rightside-blocks-middle">
                {medicalcount?.Measurement}
              </p>
            </div>
          </div>
          <div>
            <div className="search-sort-container">
              <div className="search-addrecord-div">
                <div
                  className={`${
                    isFloatingBtn ? "search-addrecord-div-hideShow" : ""
                  }`}
                >
                  <div className="floating-hideShow-btn">
                    <Fab>
                      <AddIcon
                        color="success"
                        onClick={() => {
                          setnewrec(!addnewrec);
                        }}
                      />
                    </Fab>
                    <Fab>
                      <SearchIcon
                        color="action"
                        onClick={() => {
                          setsearchinput(!showSearchInput);
                        }}
                      />
                    </Fab>
                    <input
                      className={`medicaldashboard-search-input ${
                        showSearchInput ? "" : "hide-search"
                      }`}
                      placeholder="Search By Name"
                      value={searchkey}
                      onChange={(e) => handelsearchinput(e)}
                    />
                  </div>

                  {/* 
                  <button
                    className="medicalDashboard-add-new-record-btn"
                    onClick={() => {
                      this.setState({ addnewrecord: true });
                    }}
                  >
                    <MdIcons.MdAddBox />
                    Add New Record
                  </button> */}
                </div>
                <div className="floating-btn">
                  <Tooltip title="Add & Search Medical Record" placement="left">
                    <Fab
                      onClick={() => {
                        setisfloating(!isFloatingBtn);
                      }}
                    >
                      {isFloatingBtn ? (
                        <AddIcon color="primary" />
                      ) : (
                        <CloseIcon
                          color="secondary"
                          onClick={() => {
                            setsearchinput(false);
                          }}
                        />
                      )}
                    </Fab>
                  </Tooltip>
                </div>
              </div>
            </div>
            {!defaultmsg ? (
              <>
                <div>
                  {medicaldetails[0]?.Vaccination?.map((res) => {
                    return (
                      <Medicalcard medicaldetails={res} type={"vaccine"} />
                    );
                  })}
                  {medicaldetails[0]?.Allergy?.map((res) => {
                    return (
                      <Medicalcard medicaldetails={res} type={"Allergy"} />
                    );
                  })}
                  {medicaldetails[0]?.Surgery?.map((res) => {
                    return (
                      <Medicalcard medicaldetails={res} type={"Surgery"} />
                    );
                  })}
                  {medicaldetails[0]?.Medication?.map((res) => {
                    return (
                      <Medicalcard medicaldetails={res} type={"Medication"} />
                    );
                  })}
                  {medicaldetails[0]?.Medicalcondition?.map((res) => {
                    return (
                      <Medicalcard
                        medicaldetails={res}
                        type={"Medicalcondition"}
                      />
                    );
                  })}
                  {medicaldetails[0]?.Measurement?.map((res) => {
                    return (
                      <Medicalcard medicaldetails={res} type={"Measurement"} />
                    );
                  })}
                </div>
              </>
            ) : (
              <>
                <div className="text_container">
                  <p
                    className="no_medical"
                    style={{
                      font: "400 14px/20px Roboto,Helvetica Neue,sans-serif",
                      fontSize: "25px",
                      marginTop: 50,
                    }}
                  >
                    No medical records found
                  </p>
                  <br></br>
                  <p
                    style={{
                      font: "400 14px/20px Roboto,Helvetica Neue,sans-serif",
                      fontSize: "25px",
                    }}
                  >
                    Want to add a medical record?
                  </p>
                  <Button
                    className="mt-3"
                    style={{
                      color: "lightgray",
                      fontSize: "20px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      setnewrec(!addnewrec);
                    }}
                  >
                    Click Here
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Dialog
        PaperProps={{
          style: { borderRadius: 15, maxWidth: "835px" },
        }}
        id="AddRecordDialog"
        open={addnewrec}
        onClose={() => setnewrec(!addnewrec)}
        aria-labelledby="form-dialog-title"
      >
        <AddNewRecord
          className="Add_New_Record_Dialog"
          closeDialog={setnewrec}
        />
      </Dialog>
    </>
  );
}

export default MedicalDashboard;
